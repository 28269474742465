import type { ClassTransformOptions } from 'class-transformer'
import {
  plainToInstance as _plainToInstance,
  instanceToPlain as _instanceToPlain,
} from 'class-transformer'
import type {
  HTTP_METHODS, Item, Node,
} from '@whispli/client/tenant/openapi/types'
import type { TENANT_API_TYPES_ENUM } from '@whispli/client/tenant/openapi/client/constants'
import {
  TENANT_API_PATH_PARAMS_TYPE_MAP,
  TENANT_API_PATH_REQUEST_BODY_TYPE_MAP,
  TENANT_API_PATH_RESPONSE_BODY_TYPE_MAP,
  RESPONSE_BODY_TYPE,
  REQUEST_BODY_TYPE,
  PARAMS_TYPE,
} from '@whispli/client/tenant/openapi/client/constants'
import omit from 'ramda/es/omit'
import memoizeWith from 'ramda/es/memoizeWith'
import { isProduction } from '@whispli/utils/env'

const DEFAULT_OPTIONS: ClassTransformOptions = {
  /**
   * Strict mode. No extra properties. The OpenAPI document is the authority.
   * All fields must be documented in the OpenAPI document.
   * All extraneous properties will be discarded.
   */
  excludeExtraneousValues: true,
  exposeUnsetFields: false,
}

/** Provides `plainToInstance` with default options */
export const plainToInstance = <
  TModel extends Node,
  TData extends Item = Item,
  Collection extends boolean = false
>(
  Model,
  data,
  options?: ClassTransformOptions,
) => <Collection extends true ? TModel[] : TModel>_plainToInstance<TModel, TData>(
  Model,
  data,
  {
    ...DEFAULT_OPTIONS,
    ...options,
  }
)

export const EXCLUDE_PREFIXES =  [ '__' ]

/** Provides `instanceToPlain` with default options */
export const instanceToPlain = <
  TData extends Item,
  Collection extends boolean = false
>(
  obj,
  options?: ClassTransformOptions,
) => <Collection extends true ? TData[] : TData>_instanceToPlain<TData>(obj, {
  ...DEFAULT_OPTIONS,
  excludePrefixes: EXCLUDE_PREFIXES, // __typename, __key
  ...options,
})

export const omitTypename = omit([ '__typename' ])

const cache = Object.create(null)

const getMap = (mapName: typeof RESPONSE_BODY_TYPE | typeof REQUEST_BODY_TYPE | typeof PARAMS_TYPE) => {
  switch (mapName) {
    case RESPONSE_BODY_TYPE:
      return TENANT_API_PATH_RESPONSE_BODY_TYPE_MAP
    case REQUEST_BODY_TYPE:
      return TENANT_API_PATH_REQUEST_BODY_TYPE_MAP
    case PARAMS_TYPE:
      return TENANT_API_PATH_PARAMS_TYPE_MAP
    default:
      throw new Error(`Unknown map name: ${mapName}`)
  }
}

const _getTypenameByMethodAndPath = (
  method: HTTP_METHODS | Lowercase<HTTP_METHODS>,
  fullPath: string,
  mapName: typeof RESPONSE_BODY_TYPE | typeof REQUEST_BODY_TYPE | typeof PARAMS_TYPE
): TENANT_API_TYPES_ENUM | null | never => {
  const [ path ] = fullPath.split('?')
  const entry = Object.entries(getMap(mapName)[method.toLowerCase()]).find(([ pathRegExp, __typename ]) => {
    return (cache[pathRegExp] ||= new RegExp(pathRegExp)).test(path)
  })

  return entry
    ? <TENANT_API_TYPES_ENUM>entry[1]
    : null
}
export const getTypenameByMethodAndPath = isProduction
  ? memoizeWith((...args) => args.join(''), _getTypenameByMethodAndPath)
  : _getTypenameByMethodAndPath


/**
 * Get all instance properties similar to Object.entries
 *
 * @note DTO instances are not plain objects, We cannot use Object.entries to get object entries.
 * @param obj
 */
export function getInstanceEntries<T extends [string, any][]>(obj: Item): Array<T[number]> {
  const properties: Array<T[number]> = []
  let proto = obj

  // Iterate over the prototype chain until the base class (which is Object)
  while (proto !== Object.prototype) {
    const keys = Object.getOwnPropertyNames(proto)

    keys.forEach(key => {
      if (key !== 'constructor') {
        const descriptor = Object.getOwnPropertyDescriptor(proto, key)
        if (descriptor) {
          if (descriptor.get) {
            properties.push([ key, descriptor.get.call(obj) ])
          } else if (typeof descriptor.value !== 'function') {
            properties.push([ key, descriptor.value ])
          }
        }
      }
    })

    proto = Object.getPrototypeOf(proto)
  }

  return properties
}

/**
 * Get all instance properties as an object
 * @param val
 */
export const getInstanceAsObject = <E extends Node>(val: E) => Object.fromEntries(getInstanceEntries(val)) as E
