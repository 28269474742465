import { fetchJsonWithInfo } from '@whispli/client/utils'
import type { RequestInitAny } from '@whispli/client/types'
import type { QueryParameters } from '@whispli/client/tenant/openapi/client/types'
import type { RESTPaginationParams } from '@whispli/client/tenant/openapi/types'
import type { PaginationPage } from '@whispli/client/tenant/openapi/client/model/PaginationPage'
import type { FilterSort } from '@whispli/client/tenant/openapi/client/model/FilterSort'
import type { JSONResponsePaginated } from '@whispli/client/tenant/openapi'
import memoizeWith from 'ramda/es/memoizeWith'
import { EmptyNoMapping } from './EmptyNoMapping'
import { isDevelopment } from '@whispli/utils/env'

const STARTS_WITH_SLASH_REGEXP = /^\/+/
const ENDS_WITH_SLASH_REGEXP = /\/+$/

export const fetchJsonWithInfoPaginated = async <T extends Record<string, any>>(
  request: RequestInfo,
  config?: RequestInitAny,
): Promise<JSONResponsePaginated<T>> => {
  const response = await fetchJsonWithInfo<T>(request, config)

  if (!response.json?.page) {
    // Ensure "paginated" resources always respond with pagination metadata
    throw new Error(`${request} Does not support pagination`)
  }

  const json = {
    data: (response.data || []) as T[],
    page: response.json.page as PaginationPage,
    sort: response.json.sort as FilterSort,
  }

  return {
    ...response,
    ...json,
    json,
  }
}

const DEFAULT_SORT_COL = 'created_at'

const DEFAULT_SORT_DIR = 'desc'

type PaginationParams = RESTPaginationParams | Record<string, string>

export const mapGQLToRESTPaginationParams = (
  {
    offset,
    limit,
    sort,
  }: Record<string, any>,
  flatten = true
): PaginationParams => {
  const searchParams: PaginationParams = {
    page: undefined,
    sort: undefined,
  }

  if (typeof offset === 'number' && typeof limit === 'number') {
    searchParams.page = {
      number: Math.ceil(offset / limit) || 1,
      size: limit,
    }
  }

  if (Array.isArray(sort) && sort.length) {
    const [
      column = DEFAULT_SORT_COL,
      direction = DEFAULT_SORT_DIR,
    ] = sort[0].split('.')

    searchParams.sort = {
      column,
      direction: direction.toLowerCase(),
    }
  }

  if (flatten) {
    return flattenSearchParams(searchParams)
  }

  return searchParams
}

export const flattenSearchParams = (
  obj: Record<string, any>,
  parentKey = '',
  result = {}
) => {
  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}[${key}]` : key

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      flattenSearchParams(obj[key], newKey, result)
    } else {
      // Remove undefined and null values
      if (obj[key] !== null && obj[key] !== undefined) {
        result[newKey] = obj[key]
      }
    }
  }

  return result
}

const ALLOWED_QUERY_PARAMS_TYPES = [ 'string', 'number', 'boolean' ]

export const makeQueryParams = (
  {
    limit,
    offset,
    sort,
    ...query
  }: QueryParameters = {},
): string => {
  const searchParams = new URLSearchParams()
  Object.entries(flattenSearchParams(query))
    .forEach(([ k, v ]) => ALLOWED_QUERY_PARAMS_TYPES.includes(typeof v) && searchParams.append(k, v as string))

  if (limit) {
    Object.entries(
      mapGQLToRESTPaginationParams(
        {
          limit,
          offset,
          sort,
        },
        true
      )
    ).forEach(e => searchParams.append(...e))
  }

  return searchParams.toString()
}

export const API_PREFIX = '/api' as const

const PROTOCOL_REGEXP = /^http(s)?:\/\//

/**
 * @note `window.location.origin` undefined Firefox 78.0 Win 10
 * @see https://sentry.io/organizations/whispli-fraudsec/issues/3100684326
 */
const ORIGIN = 'origin' in window.location
  ? window.location.origin
  // @ts-ignore
  : window.location.host ?? window.location.href.replace(PROTOCOL_REGEXP, '') ?? ''

/** @deprecated Use API_PREFIX */
export const getApiPrefix = (): string => API_PREFIX

export const removeApiPrefix = (pathname: string) =>
  pathname
    .replace(API_PREFIX.slice(1) + '/', '')
    .replace(API_PREFIX + '/', '')

export const getBaseUrl = (): string =>
  typeof window !== 'undefined'
    ? ORIGIN + API_PREFIX
    : 'http://do-not-delete.whispli.company/api'

export const isTenantAPIRequest = (url: URL | string) =>
  `${url}`.replace(window.location.origin, '').startsWith(API_PREFIX)

export const getSubdomain = () => ORIGIN.split('.')?.[0]

const _toPath = (
  path?: string | null,
  params?: QueryParameters,
): string => {
  const fullPath = getBaseUrl() + (path ? path.replace(/^(\w)/, '/$1') : '')

  return params ? `${fullPath}?${makeQueryParams(params)}` : fullPath
}

export const toPath = memoizeWith((...args) => JSON.stringify(args), _toPath)

const ENTITY_NAME_REGEXP = /whisper/gi

// API responses contain "whisper" instead of "report"
export const normalizeEntityName = (e?: string) => e
  ? e.replace(ENTITY_NAME_REGEXP, 'Report')
  : ''

// API responses contain PHP namespace separator
export const removeNamespace = (e?: string | null) => e
  ? e.split('\\').pop()
  : e

export const isIgnoredUrl = (url: string, ignored: ReadonlyArray<string>): boolean =>
  ignored.some(e => url && url === e || url.includes(e))

/**
 * Load reference or return EmptyNoMapping
 * @param reference
 */
export const loadReference = (reference: () => any) => {
  try {
    /** @note reference must be called within callback to catch reference error */
    return typeof reference === 'function' ? reference() : EmptyNoMapping
  } catch (e) {
    if (e instanceof ReferenceError) {
      if (isDevelopment) {
        console.warn(e.message + ' --- There is likely a cyclical dependency issue with the model')
      }
      return EmptyNoMapping
    }

    // Throw as normal
    throw e
  }
}

/**
 * Append base URL to resource path
 * @example
 *  appendUrlBase('/v3/whispers') => 'https://example.company/api/v3/whispers'
 * @param resourcePath
 */
export function appendUrlBase(resourcePath?: string | null): URL | null {
  if (!resourcePath) {
    return null
  }

  /**
   * @note - maintain full path '/api/' + 'v3/...'
   */
  const baseUrl = new URL(getBaseUrl())
  baseUrl.pathname = baseUrl.pathname
    .replace(STARTS_WITH_SLASH_REGEXP, '') + (resourcePath ?? '')
    .replace(ENDS_WITH_SLASH_REGEXP, '')

  return baseUrl || null
}

export { EmptyNoMapping }
