import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import { ReportStatus } from './../../overrides/model/ReportStatus.js';
import type { Constructor } from '@whispli/types';

export class __ReportSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => Object)
  public readonly name!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use anonymousFirstName
   */
  @Expose()
  @Type(() => String)
  private readonly anonymous_first_name!: string;

  public get anonymousFirstName() {
    return this.anonymous_first_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use anonymousLastName
   */
  @Expose()
  @Type(() => String)
  private readonly anonymous_last_name!: string;

  public get anonymousLastName() {
    return this.anonymous_last_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use isLocked
   */
  @Expose()
  @Type(() => Object)
  private readonly is_locked!: boolean | null;

  public get isLocked() {
    return this.is_locked
  }

  @Expose()
  @Type(() => Object)
  public readonly locale!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use syntheticStatus
   */
  @Expose()
  @Type(() => Object)
  private readonly synthetic_status!: string | null;

  public get syntheticStatus() {
    return this.synthetic_status
  }

  @Type(() => ReportStatus)
  @Expose()
  public readonly status!: ReportStatus | null;
  /**
   * @deprecated For backwards-compatibility only. Use versionInternalName
   */
  @Expose()
  @Type(() => Object)
  private readonly version_internal_name!: string | null;

  public get versionInternalName() {
    return this.version_internal_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use versionInformantName
   */
  @Expose()
  @Type(() => Object)
  private readonly version_informant_name!: string | null;

  public get versionInformantName() {
    return this.version_informant_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use versionNote
   */
  @Expose()
  @Type(() => Object)
  private readonly version_note!: string | null;

  public get versionNote() {
    return this.version_note
  }

  /**
   * @deprecated For backwards-compatibility only. Use reportLabel
   */
  @Expose()
  @Type(() => Object)
  private readonly whisper_label!: string | null;

  public get reportLabel() {
    return this.whisper_label
  }

  @Expose()
  @Type(() => Object)
  public readonly source!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }
}
export type ReportSimple = Omit<__ReportSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'anonymous_first_name' | 'anonymous_last_name' | 'is_locked' | 'synthetic_status' | 'version_internal_name' | 'version_informant_name' | 'version_note' | 'whisper_label' | 'created_at' | 'updated_at'>
export const ReportSimple = __ReportSimple as any as Constructor<ReportSimple>
