import '@whispli/utils/console'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Ref } from 'vue'
import {
  computed, ref, unref,
} from 'vue'
import { __debug__ } from '@whispli/utils/debug'
import type CaseManager from '@whispli/client/tenant/auth/strategies/case-manager'
import type Guest from '@whispli/client/tenant/auth/strategies/guest'
import type Strategy from '@whispli/client/tenant/auth/strategies/strategy'
import * as Sentry from '@sentry/vue'
import { USER_TYPE_ENUM } from '@whispli/client/tenant/constants'
import { isAdmin as _isAdmin } from '@whispli/client/tenant/auth/utils'
import { User } from '@whispli/client/tenant/openapi/overrides/model/User'
import type { __LanguagePreference } from '@whispli/client/tenant/openapi/client/model/LanguagePreference'
import { __User } from '@whispli/client/tenant/openapi/client/model/User'
import { __Informant } from '@whispli/client/tenant/openapi/client/model/Informant'
import { __ExternalUser } from '@whispli/client/tenant/openapi/client/model/ExternalUser'
import { AUTH_STRATEGY_ENUM } from '../../constants'
import type { ExternalUser } from '@whispli/client/tenant/openapi/overrides/model/ExternalUser'
import type { Informant } from '@whispli/client/tenant/openapi/overrides/model/Informant'
import { instanceToPlain } from '@whispli/client/tenant/openapi/utils'

export interface AuthState {
  strategy: Strategy | Record<string, unknown>;
  strategies: ReadonlyArray<CaseManager | Guest>;
  userId: string | null;
  type: Strategy['entityType'];
}

export const useAuthStore = defineStore('auth', () => {
  const strategy: Ref<Strategy | null> = ref(null)
  const strategies: Ref<ReadonlyArray<CaseManager | Guest>> = ref([])
  const userId: Ref<User['id'] | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const inactivityWarningVisible = ref<boolean>(false)

  const user = computed<User | null>(() => {
    return userId.value
      ? User.query()
        .with('memberships')
        .with('user_groups')
        .find(userId.value)
      : null
  })

  /**
   * Default language to translate Reports or Messages into
   *
   * @note "language_preference" data shape only used by case managers
   */
  const defaultTranslationTarget = computed<string | undefined>(() => {
    if (isExternalUser.value) {
      return
    }

    if (isInformant.value) {
      return user.value?.locale
    }

    const languagePreferences = unref(user)?.languagePreferences
    const translationLocale = languagePreferences?.translationLocale

    /**
     * If the user has a translation locale set, and it's not in the ignored list, use it
     */
    if (translationLocale && !(languagePreferences?.ignoredTranslations ?? [])?.includes(translationLocale)) {
      return translationLocale
    }

    return
  })

  const abilities = computed(() => unref(user)?.abilities ?? [])

  const strategyId = computed(() => unref(strategy)?.id || null)
  const type = computed<Strategy['entityType']>(() => unref(strategy)?.entityType ?? null)

  const isAuthenticated = computed(() => !!userId.value && !!unref(strategy)?.isAuthenticated && !!user.value)
  const isExpired = computed(() => !!unref(strategy)?.isExpired)
  const isSso = computed(() => {
    return Boolean(unref(strategy)?.isSso) && !!user.value
  })

  const isInformant = computed(() => USER_TYPE_ENUM.WHISPERER === unref(type))
  const isCaseManager = computed(() => USER_TYPE_ENUM.USER === unref(type))
  const isAdmin = computed(() => _isAdmin(unref(user)))
  const isExternalUser = computed(() => (unref(strategy)?.id === AUTH_STRATEGY_ENUM.GUEST))

  function setUser(
    _data: Partial<User | Informant | ExternalUser> & {
      id?: string,
      isGuest?: boolean,
      username?: string,
      language_preferences?: __LanguagePreference
      is_mfa_enabled?: boolean
    }) {
    // @ts-ignore
    const data = _data instanceof __User || _data instanceof __Informant || _data instanceof __ExternalUser ?
      instanceToPlain<typeof _data>(_data) :
      _data

    if (import.meta.env.VITE_SENTRY_DSN) {
      if (data) {
        const firstName = 'anonymousFirstName' in data
          ? data.anonymousFirstName
          : 'firstName' in data
            ? data.firstName
            : ''
        const lastName = 'anonymousLastName' in data
          ? data.anonymousLastName
          : 'lastName' in data
            ? data.lastName
            : ''
        // DO NOT distribute informant email
        const email = ('anonymousFirstName' in data ? null : data.email) ?? ''

        Sentry.setUser({
          id: data.id,
          email,
          dataname: `${firstName} ${lastName}`,
          ip_address: '{{auto}}',
        })
      } else {
        Sentry.setUser(null)
      }
    }

    // @todo Replace VuexORM calls
    // Sync VuexORM entity
    User.insertOrUpdate({ data })

    /**
     * Reactivity fix.
     * This will trigger an update of the user computed.
     */
    userId.value = null

    // Store ID only, use VuexORM to retrieve current state from entities/users
    userId.value = data?.id ?? null
  }

  function updateUser(data: User) {
    return User.update({ data })
  }

  function showInactivityWarning() {
    inactivityWarningVisible.value = true
  }

  function hideInactivityWarning() {
    inactivityWarningVisible.value = false
  }

  async function refetchUser() {
    try {
      const updatedUser = await unref(strategy)!.fetchUser(true)

      setUser({
        ...unref(user),
        ...updatedUser,
      } as User)
    } catch (err) {
      __debug__(err, false)
      return
    }
  }

  async function login(data) {
    if (!unref(strategy)) {
      return
    }

    try {
      await unref(strategy)!.login(data)

      return true
    } catch (err) {
      return false
    }
  }

  async function logout(...params: string[]) {
    if (!unref(strategy)) {
      return
    }

    hideInactivityWarning()

    try {
      await unref(strategy)!.logout(...params)

      return true
    } catch (err) {
      __debug__(err, false)

      return false
    }
  }

  function clear() {
    try {
      userId.value = null

      return true
    } catch (err) {
      __debug__(err)

      return false
    }
  }

  async function refresh() {
    if (!unref(strategy)) {
      return
    }

    try {
      await unref(strategy)?.refresh()

      return true
    } catch (err) {
      __debug__(err, false)
      throw err
    }
  }

  return {
    abilities,
    strategy,
    strategies,
    userId,
    type,
    strategyId,
    user,
    defaultTranslationTarget,
    isAuthenticated,
    isAdmin,
    isExpired,
    isSso,
    isInformant,
    isCaseManager,
    isExternalUser,
    loading,
    setUser,
    refetchUser,
    login,
    logout,
    clear,
    refresh,
    updateUser,
    showInactivityWarning,
    hideInactivityWarning,
    inactivityWarningVisible,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
