import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __UserAbility extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use entityType
   */
  @Expose()
  @Type(() => Object)
  private readonly entity_type!: string | null;

  public get entityType() {
    return this.entity_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use entityId
   */
  @Expose()
  @Type(() => Object)
  private readonly entity_id!: string | null;

  public get entityId() {
    return this.entity_id
  }
}
export type UserAbility = Omit<__UserAbility, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'entity_type' | 'entity_id'>
export const UserAbility = __UserAbility as any as Constructor<UserAbility>
