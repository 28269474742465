import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import { TranslationSetting } from './../../overrides/model/TranslationSetting.js';
import { LandingPageBlock } from './../../overrides/model/LandingPageBlock.js';
import { LandingPageExtra } from './../../overrides/model/LandingPageExtra.js';
import type { Constructor } from '@whispli/types';

export class __LandingPage extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use isLive
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_live!: boolean;

  public get isLive() {
    return this.is_live
  }

  @Expose()
  @Type(() => String)
  public readonly locale!: string;
  @Expose()
  @Type(() => Object)
  public readonly slug!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use primaryColor
   */
  @Expose()
  @Type(() => Object)
  private readonly primary_color!: string | null;

  public get primaryColor() {
    return this.primary_color
  }

  /**
   * @deprecated For backwards-compatibility only. Use secondaryColor
   */
  @Expose()
  @Type(() => Object)
  private readonly secondary_color!: string | null;

  public get secondaryColor() {
    return this.secondary_color
  }

  @Expose()
  @Type(() => Object)
  public readonly password!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use explanatoryNote
   */
  @Expose()
  @Type(() => Object)
  private readonly explanatory_note!: string | null;

  public get explanatoryNote() {
    return this.explanatory_note
  }

  /**
   * @deprecated For backwards-compatibility only. Use mobileDeepLink
   */
  @Expose()
  @Type(() => Object)
  private readonly mobile_deep_link!: string | null;

  public get mobileDeepLink() {
    return this.mobile_deep_link
  }

  /**
   * @deprecated For backwards-compatibility only. Use translationSettings
   */
  @Type(() => TranslationSetting)
  @Expose()
  private readonly translation_settings!: (TranslationSetting)[];

  public get translationSettings() {
    return this.translation_settings
  }

  /**
   * @deprecated For backwards-compatibility only. Use landingPageBlocks
   */
  @Type(() => LandingPageBlock)
  @Expose()
  private readonly landing_page_blocks!: (LandingPageBlock)[];

  public get landingPageBlocks() {
    return this.landing_page_blocks
  }

  @Type(() => LandingPageExtra)
  @Expose()
  public readonly extra!: LandingPageExtra | null;
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use archivedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly archived_at!: string | null;

  public get archivedAt() {
    return this.archived_at
  }
}
export type LandingPage = Omit<__LandingPage, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'is_live' | 'primary_color' | 'secondary_color' | 'explanatory_note' | 'mobile_deep_link' | 'translation_settings' | 'landing_page_blocks' | 'created_at' | 'updated_at' | 'archived_at'>
export const LandingPage = __LandingPage as any as Constructor<LandingPage>
