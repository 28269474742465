import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __ReportStatus extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use isDefault
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_default!: boolean;

  public get isDefault() {
    return this.is_default
  }

  /**
   * @deprecated For backwards-compatibility only. Use isEndType
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_end_type!: boolean;

  public get isEndType() {
    return this.is_end_type
  }

  @Expose()
  @Type(() => Number)
  public readonly order!: number;
  @Expose()
  @Type(() => Object)
  public readonly color!: string | null;
  @Expose()
  @Type(() => Boolean)
  public readonly protected!: boolean;
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use deletedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly deleted_at!: string | null;

  public get deletedAt() {
    return this.deleted_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use archivedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly archived_at!: string | null;

  public get archivedAt() {
    return this.archived_at
  }
}
export type ReportStatus = Omit<__ReportStatus, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'is_default' | 'is_end_type' | 'created_at' | 'updated_at' | 'deleted_at' | 'archived_at'>
export const ReportStatus = __ReportStatus as any as Constructor<ReportStatus>
