import { AppError, ERROR_STATUS_CUSTOM_CODE_ENUM } from '@whispli/error'
import __debug__ from '@whispli/ui/utils/debug'
import { BASE_URL, BLOCK_TYPE } from '@/client/tenant/constants'
import type {
  GetPageResponse,
  PageData,
  CustomLandingPage,
} from '@/client/tenant/types'
import type { OrganizationData } from '@/client/tenant/types'
import { find } from '@/i18n'
import { PageAdapter } from './PageAdapter'
import { LOCALES } from '@whispli/i18n/constants'
import type { FeatureFlags, State } from '@/types'
import { mapLegacyBlockType } from '@/client/tenant/PageAdapter/utils'

export { PageAdapter } from './PageAdapter'
export * from './types'
export * from './constants'

export const getURL = (): {
  locale: string | null;
  isCustom: boolean;
  prefix: 'preview' | 'live';
  segments: string[];
  slug: string;
  url: URL;
  targetUrl: URL;
} => {
  /**
   * The current page href
   * @example https://tenant.whispli.com/lp/slug
   * @example https://tenant.whispli.com/lp/slug?locale=en
   * @example https://tenant.whispli.com/lp/slug?preview=1
   */
  const url = new URL(window.location.href)
  const segments = url.pathname.split('/').filter(Boolean)
  const slug = segments[segments.length - 1]
  const isCustom = segments[1] === 'pages'
  const prefix = url.searchParams.get('preview') === '1'
    ? 'preview'
    : 'live'
  const targetUrl = new URL(`${BASE_URL}/v3/${prefix}-landing-pages/${slug}`)
  const locale = url.searchParams.get('locale')
  if (locale) {
    targetUrl.searchParams.append('locale', locale)
  }

  return {
    locale,
    isCustom,
    prefix,
    segments,
    slug,
    url,
    targetUrl,
  }
}

/**
 * Fetch all required data from Tenant API
 * @todo These API calls are a significant bottle neck.
 * Implement HTTP cache (Cloudfront) to reduce TTFP.
 */
export const getData = async (): Promise<State> => {
  const { slug, targetUrl } = getURL()

  postLandingPageTrack(slug)

  try {
    const {
      organization,
      featureFlags,
      ...pageData
    } = await getLandingPage(targetUrl.toString())

    const page = new PageAdapter(
      pageData,
      LOCALES
    )

    return {
      error: null,
      featureFlags,
      organization,
      page,
    }
  } catch (error) {
    __debug__(error, true)

    return {
      error: error instanceof AppError ? error : null,
      featureFlags: null,
      organization: null,
      page: null,
    }
  }
}

const init = Object.freeze({
  method: 'GET',
  mode: 'cors' as const,
  headers: Object.freeze({ 'Accept': 'application/json' }),
})

export const getCustomLandingPage = async (
  slug: string,
  locale: string,
): Promise<CustomLandingPage | null> => {
  try {
    const response = await fetch(`${BASE_URL}/custom-landing-pages`, init)
    const data = (await response.json())?.data

    // Find landing page with browser locale or,
    // fallback to custom landing page with slug specified by user
    return data.find(e => e.slug === `${slug}-${locale}` || e.slug === slug) ?? null
  } catch (err) {
    __debug__(err, true)

    return null
  }
}

export const postLandingPageTrack = async (slug: string): Promise<void> => {
  try {
    await fetch(`${BASE_URL}/v3/live-landing-pages/${slug}/t`, init)
  } catch (err) {
    __debug__(err, true)
  }
}

type MergedPageData = PageData & { organization: OrganizationData } & { featureFlags: FeatureFlags }

export const getLandingPage = async (
  url: string
): Promise<MergedPageData | never> => {
  const response = await fetch(url, init)

  let data: GetPageResponse['data']

  if (!response.ok || !(data = (await response.json())?.data)) {
    const error = new AppError(
      'Could not fetch page data',
      'FETCH_PAGE_DATA_FAILED',
      ERROR_STATUS_CUSTOM_CODE_ENUM.FETCH_PAGE_DATA_FAILED,
      { status: response.status }
    )
    __debug__(error, true)
    throw error
  }

  const normalizedData: MergedPageData = {
    id: data.id ?? data.slug ?? '',
    createdAt: data.created_at,
    mobileDeepLink: data.mobile_deep_link,
    blocks: (data.landing_page_blocks ?? [])
      .filter((e) => e.is_visible)
      .map((e) => {
        return mapLegacyBlockType(
          {
            body: e.description,
            id: e.id,
            channelId: e.channel_id,
            landingPageBlockTypeId: e.landing_page_block_type_id,
            mobileDeepLink: e.mobile_deep_link ?? null,
            order: e.order,
            title: e.title,
            type: BLOCK_TYPE.DEFAULT,
            url: e.media?.[0]?.download_url ?? null,
            visible: e.is_visible,
            translations: e.translations,
          },
          data.landing_page_block_types ?? data.extra?.landing_page_block_types ?? []
        )
      })
      .sort((a, b) => a.order - b.order),
    translationSettings: (data.translation_settings ?? []).map((e) => {
      return {
        locale: find(e.locale.code),
        isDefault: e.is_default,
      }
    }),
    organization: normalizeOrganization(data),
    featureFlags: {
      LANDING_PAGE_DISABLE_MOBILE:
        !!(data.feature_flags ?? data.extra?.feature_flags ?? {})['LANDING-PAGE-DISABLE-MOBILE'],
    },
  }

  return normalizedData
}

const normalizeOrganization = (data: GetPageResponse['data']) => {
  const organizationLogo = data.extra?.organization_logo ?? ''

  return {
    footer: data.extra?.organization_footer ?? '',
    logo: (
      Array.isArray(organizationLogo)
        ? organizationLogo[0]
        : organizationLogo
    ) ?? '',
    name: data.extra?.organization_name ?? '',
    title_prepend: data.extra?.organization_title_prepend ?? '',
  }
}
