import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import type { ChannelSimpleChannelableTypeEnum } from './../constants.js';
import type { Constructor } from '@whispli/types';

export class __ChannelSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  @Expose()
  @Type(() => String)
  public readonly locale!: string;
  /**
   * @deprecated For backwards-compatibility only. Use channelableType
   */
  @Type(() => String)
  @Expose()
  private readonly channelable_type!: ChannelSimpleChannelableTypeEnum;

  public get channelableType() {
    return this.channelable_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use channelableId
   */
  @Expose()
  @Type(() => String)
  private readonly channelable_id!: string;

  public get channelableId() {
    return this.channelable_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use channelTypeId
   */
  @Expose()
  @Type(() => String)
  private readonly channel_type_id!: string;

  public get channelTypeId() {
    return this.channel_type_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use channelStatusId
   */
  @Expose()
  @Type(() => String)
  private readonly channel_status_id!: string;

  public get channelStatusId() {
    return this.channel_status_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use liveAt
   */
  @Expose()
  @Type(() => String)
  private readonly live_at!: string;

  public get liveAt() {
    return this.live_at
  }
}
export type ChannelSimple = Omit<__ChannelSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'channelable_type' | 'channelable_id' | 'channel_type_id' | 'channel_status_id' | 'created_at' | 'updated_at' | 'live_at'>
export const ChannelSimple = __ChannelSimple as any as Constructor<ChannelSimple>
