import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import { LandingPageExtraFeatureFlags } from './../../overrides/model/LandingPageExtraFeatureFlags.js';
import { Transform } from 'class-transformer';
import { default as camelize } from 'camelize-ts';
import { LandingPageBlockType } from './../../overrides/model/LandingPageBlockType.js';
import type { Constructor } from '@whispli/types';

export class __LandingPageExtra extends ResponseBody {
  /**
   * @deprecated For backwards-compatibility only. Use organizationName
   */
  @Expose()
  @Type(() => String)
  private readonly organization_name!: string;

  public get organizationName() {
    return this.organization_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use organizationFooter
   */
  @Expose()
  @Type(() => String)
  private readonly organization_footer!: string;

  public get organizationFooter() {
    return this.organization_footer
  }

  /**
   * @deprecated For backwards-compatibility only. Use organizationTitlePrepend
   */
  @Expose()
  @Type(() => Object)
  private readonly organization_title_prepend!: string | null;

  public get organizationTitlePrepend() {
    return this.organization_title_prepend
  }

  /**
   * @deprecated For backwards-compatibility only. Use organizationLogo
   */
  @Expose()
  @Type(() => String)
  private readonly organization_logo!: string;

  public get organizationLogo() {
    return this.organization_logo
  }

  /**
   * @todo property is of unknown type
   * @deprecated For backwards-compatibility only. Use featureFlags
   */
  @Type(() => LandingPageExtraFeatureFlags)
  @Type(() => Object)
  @Transform(({ obj }) => typeof obj.feature_flags === 'object' ? camelize(obj.feature_flags) : null)
  @Expose()
  private readonly feature_flags!: LandingPageExtraFeatureFlags;

  public get featureFlags() {
    return this.feature_flags
  }

  /**
   * @deprecated For backwards-compatibility only. Use landingPageBlockTypes
   */
  @Type(() => LandingPageBlockType)
  @Expose()
  private readonly landing_page_block_types!: (LandingPageBlockType)[];

  public get landingPageBlockTypes() {
    return this.landing_page_block_types
  }
}
export type LandingPageExtra = Omit<__LandingPageExtra, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'organization_name' | 'organization_footer' | 'organization_title_prepend' | 'organization_logo' | 'feature_flags' | 'landing_page_block_types'>
export const LandingPageExtra = __LandingPageExtra as any as Constructor<LandingPageExtra>
