import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import { MediaSimple } from './../../overrides/model/MediaSimple.js';
import { Transform } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __UserSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => Object)
  public readonly name?: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use firstName
   */
  @Expose()
  @Type(() => String)
  public readonly first_name!: string;

  public get firstName() {
    return this.first_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use lastName
   */
  @Expose()
  @Type(() => String)
  public readonly last_name!: string;

  public get lastName() {
    return this.last_name
  }

  @Expose()
  @Type(() => Object)
  public readonly email!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use jobTitle
   */
  @Expose()
  @Type(() => Object)
  private readonly job_title!: string | null;

  public get jobTitle() {
    return this.job_title
  }

  @Type(() => MediaSimple)
  @Expose()
  public readonly avatar?: MediaSimple | null;
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use deletedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly deleted_at!: string | null;

  public get deletedAt() {
    return this.deleted_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use isArchived
   * @default false
   */
  @Expose()
  @Type(() => Boolean)
  @Transform(({ obj }) => !!obj.deleted_at)
  private readonly is_archived!: boolean;

  public get isArchived() {
    return this.is_archived
  }
}
export type UserSimple = Omit<__UserSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'job_title' | 'created_at' | 'updated_at' | 'deleted_at' | 'is_archived'>
export const UserSimple = __UserSimple as any as Constructor<UserSimple>
