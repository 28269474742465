import memoizeWith from 'ramda/es/memoizeWith'
import mergeDeepRight from 'ramda/es/mergeDeepRight'
import { RESTClient } from '@whispli/client/rest'
import type { JSONResponse, RequestInitAny } from '@whispli/client/types'
import {
  ACCEPT_LANGUAGE,
  fetchAny, getLocale, keyFn,
} from '@whispli/client/utils'
import { appendCacheParam, setXAppTimestamp } from '@whispli/client/tenant/cache'
import { getDataFromResponse } from '@whispli/client/tenant/response'
import { getBaseUrl } from '@whispli/client/tenant/openapi/overrides/utils'
import AppError from '@whispli/error/app-error'
import {
  ERROR_ENUM, ERROR_MESSAGES, ERROR_STATUS_CODE_ENUM,
} from '@whispli/error'
import type {
  Item, ResponseBody, Node,
} from '@whispli/client/tenant/openapi/types'
import { ResponseError } from '@whispli/client/tenant/openapi/error'

/** Base REST Tenant API Client */
export class TenantAPIClientBase extends RESTClient {
  constructor(public baseURL: URL) {
    super(baseURL)
  }

  // @ts-ignore
  public async fetch<T extends Node = Node, U extends Item<T> = Item<T>>(
    url: string,
    init: RequestInitAny = {},
  ) {
    return await this.getJsonFromResponse<T, U>(
      await this.getResponse(url, init),
      url,
      init,
    )
  }

  // eslint-disable-next-line max-len
  public async fetchJsonWithInfo<T extends Node, U extends Item<T> = Item<T>>(
    url: string,
    init: RequestInitAny = {},
  ): Promise<JSONResponse<T>> {
    const response = await this.getResponse(url, init)
    try {
      const json = await this.getJsonFromResponse<T, U>(
        response,
        url,
        init,
      )

      return {
        data: json.data,
        page: json.page,
        sort: json.sort,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        ok: response.ok,
        json,
        response,
        request: url,
      }
    } catch (err) {
      const json = await this.getJsonData(response)
      return {
        data: json.data ?? null,
        status: response?.status || 500,
        statusText: response?.statusText || 'Fetch failed',
        headers: response?.headers,
        ok: false,
        json,
        response,
        request: url,
      }
    }
  }

  public fetchJsonWithInfoCached = memoizeWith(keyFn, this.fetchJsonWithInfo)

  private async getJsonData(
    response: Response,
  ): Promise<any | undefined> {
    try {
      if (response instanceof Response) {
        return await response.clone().json()
      } else {
        return
      }
    } catch (err) {
      return
    }
  }

  private async getResponse(
    url: string,
    init: RequestInitAny = {},
  ) {
    const mergedInit = mergeDeepRight<RequestInit, RequestInit>(DEFAULT_INIT, init) as RequestInit
    const locale = getLocale(init)
    const response = await fetchAny(
      appendCacheParam({
        url,
        baseURL: this.baseURL,
        locale,
        ...init,
      }),
      {
        ...mergedInit,
        headers: {
          ...mergedInit.headers,
          [ACCEPT_LANGUAGE]: locale,
        },
      },
    )

    setXAppTimestamp(response)

    return response
  }

  private getIsPaginatedResponse(response?: { data?: { page?: unknown, sort?: unknown }  }) {
    return Boolean(response?.data && ('page' in response.data || 'sort' in response.data))
  }

  private async getJsonFromResponse<T extends Node, U extends Item<T> = Item<T>>(
    response: Response,
    url: string,
    init: RequestInitAny = {}
  ): Promise<ResponseBody<T, U>> {
    const status = response?.status

    if (status === 204) {
      return { data: null }
    } else if (status >= 200 && status < 400) {
      try {
        const json = await response.json()
        const paginatedFields = this.getIsPaginatedResponse(json) ? json : {}

        return {
          ...paginatedFields,
          // Unwrapped response.data
          data: getDataFromResponse({
            data: json && ('data' in json) ? json.data : json,
            url,
            headers: response.headers,
          }),
        }
      } catch (err) {
        // Handle empty DELETE response body
        if (init?.method === 'DELETE') {
          return { data: null }
        }

        throw err
      }
    }

    throw new ResponseError(
      response.statusText || 'Fetch failed',
      { response }
    )
  }
}

export class TenantAPIClient extends TenantAPIClientBase {

}

/** @deprecated Use fetchFn or queryClient */
export const tenantAPIClient = new TenantAPIClient(new URL(getBaseUrl()))

const DEFAULT_INIT: RequestInit = Object.freeze({
  credentials: 'same-origin' as const,
  headers: {
    'Accept': 'application/json',
    'Accept-Language': '*',
    'Content-Type': 'application/json',
  },
  method: 'GET' as const,
  mode: 'same-origin' as const,
}) as RequestInit
