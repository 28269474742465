import { MediaSimple as Base } from '@whispli/client/tenant/openapi/client/model/MediaSimple'

export class MediaSimple extends Base {
  /**
   * @deprecated For backwards-compatibility only. use downloadUrl
   */
  public get url() {
    return this.downloadUrl
  }
}
