import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import type { MediaSimpleFilterableTypeEnum } from './../constants.js';
import type { MediaSimplePreviewStatusEnum } from './../constants.js';
import type { MediaSimpleScanStatusEnum } from './../constants.js';
import type { MediaSimpleModelTypeEnum } from './../constants.js';
import { MediaSimpleVariant } from './../../overrides/model/MediaSimpleVariant.js';
import type { Constructor } from '@whispli/types';

export class __MediaSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use collectionName
   */
  @Expose()
  @Type(() => String)
  private readonly collection_name!: string;

  public get collectionName() {
    return this.collection_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use filterableType
   */
  @Type(() => String)
  @Expose()
  private readonly filterable_type?: MediaSimpleFilterableTypeEnum | null;

  public get filterableType() {
    return this.filterable_type
  }

  @Expose()
  @Type(() => Object)
  public readonly uuid!: string | null;
  /**
   * @deprecated For backwards-compatibility only. Use fileName
   */
  @Expose()
  @Type(() => String)
  private readonly file_name!: string;

  public get fileName() {
    return this.file_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use mimeType
   */
  @Expose()
  @Type(() => String)
  private readonly mime_type!: string;

  public get mimeType() {
    return this.mime_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use orderColumn
   */
  @Expose()
  @Type(() => Number)
  private readonly order_column!: number;

  public get orderColumn() {
    return this.order_column
  }

  /**
   * @deprecated For backwards-compatibility only. Use previewUrl
   */
  @Expose()
  @Type(() => Object)
  private readonly preview_url?: string | null;

  public get previewUrl() {
    return this.preview_url
  }

  /**
   * @deprecated For backwards-compatibility only. Use downloadUrl
   */
  @Expose()
  @Type(() => Object)
  private readonly download_url?: string | null;

  public get downloadUrl() {
    return this.download_url
  }

  /**
   * @deprecated For backwards-compatibility only. Use previewStatus
   */
  @Type(() => String)
  @Expose()
  private readonly preview_status?: MediaSimplePreviewStatusEnum | null;

  public get previewStatus() {
    return this.preview_status
  }

  @Expose()
  @Type(() => Object)
  public readonly size!: number | null;
  /**
   * @deprecated For backwards-compatibility only. Use scanStatus
   */
  @Type(() => String)
  @Expose()
  private readonly scan_status?: MediaSimpleScanStatusEnum | null;

  public get scanStatus() {
    return this.scan_status
  }

  /**
   * @deprecated For backwards-compatibility only. Use modelType
   */
  @Type(() => String)
  @Expose()
  private readonly model_type!: MediaSimpleModelTypeEnum;

  public get modelType() {
    return this.model_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdByType
   */
  @Expose()
  @Type(() => Object)
  private readonly created_by_type!: string | null;

  public get createdByType() {
    return this.created_by_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdById
   */
  @Expose()
  @Type(() => Object)
  private readonly created_by_id!: string | null;

  public get createdById() {
    return this.created_by_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }

  @Type(() => MediaSimpleVariant)
  @Expose()
  public readonly variants?: (MediaSimpleVariant)[];
}

interface OptionalAlias {
  filterableType?: any;
  previewUrl?: string | null | undefined;
  downloadUrl?: string | null | undefined;
  previewStatus?: any;
  scanStatus?: any;
}
export type MediaSimple = Omit<__MediaSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'collection_name' | 'filterable_type' | 'file_name' | 'mime_type' | 'order_column' | 'preview_url' | 'download_url' | 'preview_status' | 'scan_status' | 'model_type' | 'created_by_type' | 'created_by_id' | 'created_at' | 'updated_at' | 'filterableType' | 'previewUrl' | 'downloadUrl' | 'previewStatus' | 'scanStatus'> & OptionalAlias
export const MediaSimple = __MediaSimple as any as Constructor<MediaSimple>
