import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import { Locale } from './../../overrides/model/Locale.js';
import type { Constructor } from '@whispli/types';

export class __TranslationSetting extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  /**
   * @deprecated For backwards-compatibility only. Use modelId
   */
  @Expose()
  @Type(() => String)
  private readonly model_id!: string;

  public get modelId() {
    return this.model_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use modelType
   */
  @Expose()
  @Type(() => String)
  private readonly model_type!: string;

  public get modelType() {
    return this.model_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use localeId
   */
  @Expose()
  @Type(() => String)
  private readonly locale_id!: string;

  public get localeId() {
    return this.locale_id
  }

  @Type(() => Locale)
  @Expose()
  public readonly locale!: Locale | null;
  /**
   * @deprecated For backwards-compatibility only. Use isEnabled
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_enabled!: boolean;

  public get isEnabled() {
    return this.is_enabled
  }

  /**
   * @deprecated For backwards-compatibility only. Use isDefault
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_default!: boolean;

  public get isDefault() {
    return this.is_default
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }
}
export type TranslationSetting = Omit<__TranslationSetting, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'model_id' | 'model_type' | 'locale_id' | 'is_enabled' | 'is_default' | 'created_at' | 'updated_at'>
export const TranslationSetting = __TranslationSetting as any as Constructor<TranslationSetting>
