import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import type { MediaSimpleVariantTypeEnum } from './../constants.js';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __MediaSimpleVariant extends ResponseBody {
  @Type(() => String)
  @Expose()
  public readonly type!: MediaSimpleVariantTypeEnum;
}
export type MediaSimpleVariant = Omit<__MediaSimpleVariant, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual'>
export const MediaSimpleVariant = __MediaSimpleVariant as any as Constructor<MediaSimpleVariant>
