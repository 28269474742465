import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import { ReportSimple } from './../../overrides/model/ReportSimple.js';
import { ProjectSimple } from './../../overrides/model/ProjectSimple.js';
import { UserGroup } from './../../overrides/model/UserGroup.js';
import { MediaSimple } from './../../overrides/model/MediaSimple.js';
import { UserRoleSimple } from './../../overrides/model/UserRoleSimple.js';
import { UserAbility } from './../../overrides/model/UserAbility.js';
import { LanguagePreference } from './../../overrides/model/LanguagePreference.js';
import { DashboardPreference } from './../../overrides/model/DashboardPreference.js';
import { Transform } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __User extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use firstName
   */
  @Expose()
  @Type(() => String)
  public readonly first_name!: string;

  public get firstName() {
    return this.first_name
  }

  /**
   * @deprecated For backwards-compatibility only. Use lastName
   */
  @Expose()
  @Type(() => String)
  public readonly last_name!: string;

  public get lastName() {
    return this.last_name
  }

  @Expose()
  @Type(() => String)
  public readonly email!: string;
  /**
   * @deprecated For backwards-compatibility only. Use jobTitle
   */
  @Expose()
  @Type(() => Object)
  private readonly job_title!: string | null;

  public get jobTitle() {
    return this.job_title
  }

  @Expose()
  @Type(() => String)
  public readonly timezone!: string;
  /**
   * @deprecated For backwards-compatibility only. Use samlUid
   */
  @Expose()
  @Type(() => Object)
  private readonly saml_uid!: string | null;

  public get samlUid() {
    return this.saml_uid
  }

  /**
   * @deprecated For backwards-compatibility only. Use isBillable
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_billable!: boolean;

  public get isBillable() {
    return this.is_billable
  }

  /**
   * @deprecated For backwards-compatibility only. Use isMfaEnabled
   */
  @Expose()
  @Type(() => Boolean)
  private readonly is_mfa_enabled!: boolean;

  public get isMfaEnabled() {
    return this.is_mfa_enabled
  }

  /**
   * @deprecated For backwards-compatibility only. Use reports
   */
  @Type(() => ReportSimple)
  @Expose()
  private readonly whispers!: (ReportSimple)[];

  public get reports() {
    return this.whispers
  }

  @Type(() => ProjectSimple)
  @Expose()
  public readonly projects!: (ProjectSimple)[];
  /**
   * @deprecated For backwards-compatibility only. Use userGroups
   */
  @Type(() => UserGroup)
  @Expose()
  private readonly user_groups!: (UserGroup)[];

  public get userGroups() {
    return this.user_groups
  }

  @Type(() => MediaSimple)
  @Expose()
  public readonly media!: (MediaSimple)[];
  @Type(() => UserRoleSimple)
  @Expose()
  public readonly roles!: (UserRoleSimple)[];
  @Type(() => UserAbility)
  @Expose()
  public readonly abilities!: (UserAbility)[];
  /**
   * @deprecated For backwards-compatibility only. Use forbiddenAbilities
   */
  @Type(() => UserAbility)
  @Expose()
  private readonly forbidden_abilities!: (UserAbility)[];

  public get forbiddenAbilities() {
    return this.forbidden_abilities
  }

  /**
   * @deprecated For backwards-compatibility only. Use languagePreferences
   */
  @Type(() => LanguagePreference)
  @Expose()
  private readonly language_preferences!: LanguagePreference | null;

  public get languagePreferences() {
    return this.language_preferences
  }

  /**
   * @deprecated For backwards-compatibility only. Use dashboardPreferences
   */
  @Type(() => DashboardPreference)
  @Expose()
  private readonly dashboard_preferences!: DashboardPreference | null;

  public get dashboardPreferences() {
    return this.dashboard_preferences
  }

  /**
   * @deprecated For backwards-compatibility only. Use sendMessageOnEnter
   */
  @Expose()
  @Type(() => Boolean)
  private readonly send_message_on_enter!: boolean;

  public get sendMessageOnEnter() {
    return this.send_message_on_enter
  }

  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use loggedInAt
   */
  @Expose()
  @Type(() => Object)
  private readonly logged_in_at!: string | null;

  public get loggedInAt() {
    return this.logged_in_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use deletedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly deleted_at!: string | null;

  public get deletedAt() {
    return this.deleted_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use isArchived
   * @default false
   */
  @Expose()
  @Type(() => Boolean)
  @Transform(({ obj }) => !!obj.deleted_at)
  private readonly is_archived!: boolean;

  public get isArchived() {
    return this.is_archived
  }
}
export type User = Omit<__User, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'job_title' | 'saml_uid' | 'is_billable' | 'is_mfa_enabled' | 'whispers' | 'user_groups' | 'forbidden_abilities' | 'language_preferences' | 'dashboard_preferences' | 'send_message_on_enter' | 'created_at' | 'updated_at' | 'logged_in_at' | 'deleted_at' | 'is_archived'>
export const User = __User as any as Constructor<User>
