/**
 * Empty class to be used when there is no mapping for a model
 * Occasionally when there is a cyclical dependency - a model load may fail - This provides that fallback
 */
export class EmptyNoMapping {
  constructor(obj: Record<string, unknown> | null) {
    if (obj && typeof obj === 'object') {
      Object.assign(this, obj)
    }
  }
}
