import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __UserRoleSimple extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  @Expose()
  @Type(() => String)
  public readonly title!: string;
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => String)
  private readonly created_at!: string;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => String)
  private readonly updated_at!: string;

  public get updatedAt() {
    return this.updated_at
  }
}
export type UserRoleSimple = Omit<__UserRoleSimple, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'created_at' | 'updated_at'>
export const UserRoleSimple = __UserRoleSimple as any as Constructor<UserRoleSimple>
