import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { EmptyNoMapping, loadReference } from './../../overrides/utils';
import { LandingPage } from './../../overrides/model/LandingPage.js';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __LandingPageItem extends ResponseBody {
  @Type(() => LandingPage)
  @Expose()
  public readonly data!: LandingPage;
}
export type LandingPageItem = Omit<__LandingPageItem, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual'>
export const LandingPageItem = __LandingPageItem as any as Constructor<LandingPageItem>
